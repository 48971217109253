import './Scanner.scss'
import { useHistory } from 'react-router-dom'
import Title from './Title';
import ButtonLink from './ButtonLink';
import { Fragment, useState } from 'react';
import QrReader from 'react-qr-reader'
// import QrReader from 'react-qr-scanner'
import { api_postScan } from '../modules/api'
import { isScannedYet, getItemByCode, cleanupCamera } from '../modules/utils'
import { useSelector } from 'react-redux'
import useSound from 'use-sound'
import Detail from './Detail'
import errorSfx from '../sounds/error.mp3'
import successSfx from '../sounds/success.mp3'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const Scanner = (props) => {
   const [code, setCode] = useState(null)
   const [status, setStatus] = useState(null)
   // const [detailContent, setDetailContent] = useState('')
   const [showDetail, setShowDetail] = useState(false)
   const [selectedItem, setSelectedItem] = useState(null)
   const single = useSelector(state => state.db.single)
   const globalHtml = useSelector(state => state.db.html)
   const hasDetail = useSelector(state => state.db.hasDetail)
   const history = useHistory()
   const [playSuccess] = useSound(successSfx)
   const [playError] = useSound(errorSfx)

   const handleScan = data => {
      if (data) {
         // console.log('DATA=', data)
         const item = getItemByCode(data)
         // console.log('ITEM=', item)
         const exists = (item !== null)
         // console.log('EXISTS=', exists)
         const yet = isScannedYet(data)
         // console.log('YET=', yet)
         
         cleanupCamera()

         // console.debug('***Item***', item);
         // console.debug('Exists?', exists);
         // console.debug('Yet?', yet);
         // console.debug('Single?', single);
         if (exists && (!single || !yet)) {
            playSuccess()
            setStatus(true)
            api_postScan(data, 'scan', true)
            if (((item.html?.length > 0) || (globalHtml.length > 0)) && hasDetail) {
               // setDetailContent(item.html)
               setSelectedItem(item)
               setShowDetail(true)
            } else {
               setTimeout(() => {
                  goBack()
               }, 1500)    // Referme la confirmation de SUCCES après 1,5sec
            }
         } else {
            playError()
            setStatus(exists ? 'Invité déjà scanné !' : 'Invité non reconnu')
         }
         setCode(data)
      }
   }

   const handleError = err => {
      cleanupCamera()
      playError()
      console.error(err)
      setStatus('Problème de scanner...')
   }

   const goBack = () => {
      cleanupCamera()
      history.goBack()
   }

   const doCloseDetail = () => {
      cleanupCamera()
      history.goBack()
   }

   /* Pas de Toogle présence possible lors d'un scan */
   // const doOnPresence = (on) => {
   //    // console.debug('Switch presence: ', on, selectedItem);
   //    api_postScan(selectedItem.qrcode, 'manuel', on)
   // }

   var classNames = 'scanner-root'
   if (!showDetail) {
      classNames = `${classNames} ${(typeof status === 'string') ? 'red' : ((status === true) ? 'green' : '')}`
   }

   return (
      <div className={classNames}>
         {showDetail &&
            <Detail onCloseDetail={doCloseDetail} item={selectedItem} noToggle /*onPresence={doOnPresence}*/ />/* Pas de Toogle présence possible lors d'un scan */
         }
         <div className="scanner">

            {!showDetail &&
               <Fragment>
                  {(status === null) && <Title className="top">Scanner2</Title>}
                  <div className="middle">
                     {(status === null) && <QrReader
                        delay={300}
                        onError={handleError}
                        onScan={handleScan}
                        style={{ width: '100%' }}
                     />}
                     <div className="big-check">
                        {(status === true) && <FontAwesomeIcon icon={faCheckCircle}/>}
                        {(typeof status === 'string') && <FontAwesomeIcon icon={faExclamationTriangle}/>}
                     </div>
                     {/* {(status === true) && <p><FontAwesomeIcon icon={faExclamationTriangle} />Invité reconnu</p>} */}
                     {/* {(typeof status === 'string') && <p>{status}</p>} */}
                     {/* {(status !== null) && <p id="code">{code}</p>} */}
                  </div>
                  <div className="bottom">
                     <ButtonLink onClick={goBack}>Fermer</ButtonLink>
                  </div>
               </Fragment>
            }
         </div>
      </div>
   );
}

export default Scanner