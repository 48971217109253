import './About.scss'
import ButtonLink from './ButtonLink';

import logo from '../img/logo-new.png';
import logo2x from '../img/logo-new@2x.png';
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';

const About = () => {
   const history = useHistory()
   const version = useSelector(state => state.db.version)

   return (
      <div className="about">
         <img src={logo} alt="logo" srcSet={`${logo} 1x, ${logo2x} 2x`} />
         <p>Effets sonores : <a href="https://www.zapsplat.com">https://www.zapsplat.com</a></p>
         <p>Conception : <a href="https://www.convives.fr">Convives</a></p>
         <p>Réalisation technique : <a href="https://www.bigpapoo.com">Big Papoo</a></p>
         <p>Version : {version}</p>
         <ButtonLink onClick={history.goBack}>OK</ButtonLink>
      </div>
   );
}

export default About;