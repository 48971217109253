import { createSlice } from '@reduxjs/toolkit'

const _USER_LOGGED = 'user.logged'
const _APP_INIT = 'app.init'
const _DEVICE_NAME = 'device.name'
const _DEVICE_UDID = 'device.udid'
const _API_URL = 'api.url'

const initialState = {
   isLogged: (window.localStorage.getItem(_USER_LOGGED) === 'true'),
   isInit: (window.localStorage.getItem(_APP_INIT)  === 'true'),
   deviceName: window.localStorage.getItem(_DEVICE_NAME) || '',
   udid: window.localStorage.getItem(_DEVICE_UDID) || '',
   apiUrl: window.localStorage.getItem(_API_URL) || 'https://webapp-api.',
}

const authSlice = createSlice({
   name: 'auth',
   initialState: initialState,
   reducers: {
      setIsLogged(state, action) {
         state.isLogged = action.payload
         window.localStorage.setItem(_USER_LOGGED, action.payload)
      },
      setIsInit(state, action) {
         state.isInit = action.payload
         window.localStorage.setItem(_APP_INIT, action.payload)
      },
      setDeviceName(state, action) {
         console.debug('setDeviceName', action.payload);
         state.deviceName = action.payload
         window.localStorage.setItem(_DEVICE_NAME, action.payload)
      },
      setUdid(state, action) {
         console.debug('setUdid', action.payload);
         state.udid = action.payload
         window.localStorage.setItem(_DEVICE_UDID, action.payload)
      },
      setApiUrl(state, action) {
         // console.debug('setApiUrl', action.payload);
         state.apiUrl = action.payload
         window.localStorage.setItem(_API_URL, action.payload)
      }
   }
})

export const authActions = authSlice.actions

export default authSlice.reducer