import './Init.scss'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Field from './Field'
import Title from './Title'
import { Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { authActions } from '../redux/auth'
import { dbActions } from '../redux/db'

import { api_setDeviceName, api_doLogin } from '../modules/api'
import { idxdb } from './Firestore'
import ButtonLink from './ButtonLink'

const Init = () => {
   // const [logged, setLogged] = useState(false)
   const isLogged = useSelector(state => state.auth.isLogged)
   // const udid = useSelector(state => state.auth.udid)
   const apiUrl = useSelector(state => state.auth.apiUrl)
   const [error, setError] = useState(null)
   const [login, setLogin] = useState('')
   const [password, setPassword] = useState('')
   // const [url, setUrl] = useState('http://localhost:3000')
   const [name, setName] = useState('')
   const history = useHistory()
   const dispatch = useDispatch()

   const doLogin = async () => {
      setError(null)
      // console.debug('Login:', login, password)
      api_doLogin(login, password).then(async (data) => {
         // console.debug('DO LOGIN', data)
         if (data.data.login.assets) {
            await Promise.all(
               data.data.login.assets.map(asset => {
                  // console.debug('REC', asset)
                  return idxdb.images.put({ data: { base64: asset.data, type: asset.type }, key: asset.name })
               })
            )
         }
         // console.debug('DONE ASSETS', data)

         // dispatch(dbActions.setFbConfig(data.data.login.fb_config))
         dispatch(dbActions.setEventId(data.data.login.event_id))
         dispatch(dbActions.setHtml(data.data.login.html))
         dispatch(dbActions.setAdminLogin(data.data.login.admin_login))
         dispatch(dbActions.setAdminPwd(data.data.login.admin_pwd))
         dispatch(dbActions.setHasDetail(data.data.login.has_detail))
         // dispatch(dbActions.setTorch(data.data.login.torch))
         dispatch(dbActions.setSingle(data.data.login.single))
         dispatch(dbActions.setList(data.data.login.list))
         // console.debug('Init fields', data.data.login.fields);
         dispatch(dbActions.setFields(data.data.login.fields))
         dispatch(dbActions.setWithFakeData(false))   // true pour tester sur device en "fake"
         dispatch(authActions.setIsLogged(true))
         dispatch(dbActions.setFbLogin(data.data.login.fb_login))
         dispatch(dbActions.setFbPwd(data.data.login.fb_pwd))
         dispatch(dbActions.setEventName(data.data.login.event_name))
      }).catch(err => {
         dispatch(authActions.setIsLogged(false))  // Au cas où...
         setError('Problème : ' + err.statusText)
         console.error('Err doLogin', err)
      })
   }

   const doLogout = () => {
      dispatch(authActions.setIsLogged(false))
      // setLogged(false)
      setLogin('')
      setPassword('')
      history.push('/')
   }

   const doSetName = () => {
      setError(null)
      api_setDeviceName(name).then((data) => {
         // console.debug('OK SETNAME', data)
         dispatch(authActions.setIsInit(true))
         history.push('/home')
      }).catch(err => {
         setError('Problème : ' + err.statusText)
         console.error('ERR SETNAME', err)
      })
   }

   const urlChanged = (data) => {
      dispatch(authActions.setApiUrl(data))
   }

   const loginChanged = (data) => {
      setLogin(data)
   }

   const passwordChanged = (data) => {
      setPassword(data)
   }

   const nameChanged = (data) => {
      // console.debug('nameChanged');
      setName(data)
   }

   return (
      <div className="init">
         <Title className="top">Initialisation</Title>
         <div className="middle">
            {error && (
               <p className="error">{error}</p>
            )}
            {isLogged || (
               <Fragment>
                  <Field onChange={urlChanged} value={apiUrl} placeholder="URL du serveur"></Field>
                  <Field onChange={loginChanged} value={login} placeholder="Identifiant"></Field>
                  <Field onChange={passwordChanged} value={password} isPassword placeholder="Mot de passe"></Field>
                  <ButtonLink onClick={doLogin}>Se connecter</ButtonLink>
               </Fragment>
            )}
            {isLogged && (
               <Fragment>
                  <p>Pour initialiser votre appareil, donnez-lui un nom. Votre liste d'invités sera ensuite téléchargée automatiquement.</p>
                  <Field onChange={nameChanged} value={name} placeholder="Nom de l'appareil"></Field>
                  <ButtonLink onClick={doSetName}>Valider le nom</ButtonLink>
               </Fragment>
            )}
         </div>
         <div className="bottom">
            <ButtonLink onClick={doLogout}>Annuler</ButtonLink>
         </div>
      </div>
   )
}

export default Init