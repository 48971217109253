import './App.scss'
import React, { useEffect, Suspense, useCallback } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { dbActions } from './redux/db'
import { authActions } from './redux/auth'
import { v4 as uuidv4 } from 'uuid'

import { fb_handle, fb_makeTimestampFromDate, idxdb, doLoginFirebase } from './components/Firestore'

import Welcome from './components/Welcome'
import Init from './components/Init'
import Home from './components/Home'
import Scanner from './components/Scanner'
import Search from './components/Search'  // Commenter ici pour lazyloading et décommenter + bas
import Admin from './components/Admin'
import About from './components/About'
// import ButtonLink from './components/ButtonLink'
import { useClearCache } from 'react-clear-cache'
// import { mylog, mywarn } from './modules/my_logger'

// const Search = React.lazy(() => import('./components/Search'))

function App() {
  const dispatch = useDispatch()
  // const fbConfig = useSelector(state => state.db.fbConfig)
  const withFakeData = useSelector(state => state.db.withFakeData)
  const eventId = useSelector(state => state.db.eventId)
  const lastSyncTimestamp = useSelector(state => state.db.lastSyncTimestamp)
  const fbLogin = useSelector(state => state.db.fbLogin)
  const fbPwd = useSelector(state => state.db.fbPwd)
  const isLogged = useSelector(state => state.auth.isLogged)
  const isInit = useSelector(state => state.auth.isInit)
  const udid = useSelector(state => state.auth.udid)

  useClearCache(/*{auto: false}*/)  // Clear cache si version change dans /meta.json

  // console.debug('lastSyncTimestamp', lastSyncTimestamp);
  // console.debug('UDID', udid);

  // Teste si timestamp plus récent que refTimestamp
  // const isNewer = (timestamp, refTimestamp) => {
  //   console.debug('IS NEWER ?', timestamp, refTimestamp);
  //   return ((timestamp.seconds > refTimestamp.seconds) ||
  //     ((timestamp.seconds === refTimestamp.seconds) && (timestamp.nanoseconds > refTimestamp.nanoseconds)))
  // }

  const initDB = useCallback(async () => {
    console.debug('InitDB pour event #' + eventId)

    const userCred = await doLoginFirebase(fbLogin, fbPwd)
    console.debug(userCred)

    var date = fb_makeTimestampFromDate(new Date())  // Pour debug
    date = fb_makeTimestampFromDate(new Date("2000-01-01"))  // Pour alimenter en dév 1 fois
    if (process.env.NODE_ENV === 'development') {
      date = fb_makeTimestampFromDate(new Date("2000-01-01"))  // Pour prod
    } else {
      console.log('************************************************')
      console.log('************************************************')
      console.log('***Changer date', date)
      console.log('************************************************')
      console.log('************************************************')
    }
    // const date = fb_makeTimestamp(lastSyncTimestamp)
    // console.debug('DATE', lastSyncTimestamp);

    var newestTimestamp = lastSyncTimestamp

    if ((process.env.NODE_ENV !== 'development') || !withFakeData) {
      console.debug(`Mise en place onSnapshot(lastchange > ${date})`)
      // console.debug('App:useEffect');
      fb_handle.collection('qrcodes_' + eventId).where('lastchange', '>', date).onSnapshot(async (snapshot) => {
        console.debug('Snapshot');
        const allChanges = snapshot.docChanges()
        // console.debug('ALL', allChanges);
        console.debug(`Got ${allChanges.length} items`);

        await Promise.all(
          allChanges.map(change => {
            // console.debug('Change1', change);
            // console.debug('Change2', change.doc);
            // console.debug('Change3', change.doc.data());
            if (change.type === 'removed') {
              return idxdb.qrcodes.delete(change.doc.id)
            } else {
              const data = change.doc.data()
              if (data.lastchange > newestTimestamp) {
                // console.debug('IS NEWER1');
                newestTimestamp = data.lastchange.seconds
              }
              return idxdb.qrcodes.put({ data, key: change.doc.id })
            }
          })
        )
        console.info('Finished adding');
        const items1 = await idxdb.qrcodes.toArray()  // toArray -> Promise
        // console.debug('Got items', items1);
        const items2 = items1.map(record => { return record.data })
        // console.debug('Got items2', items2);
        dispatch(dbActions.setItems(items2))

        // console.debug('FINISHED - NEWEST', newestTimestamp);
        // console.debug('PREV', lastSyncTimestamp);
        if (newestTimestamp > lastSyncTimestamp) {
          // console.debug('DISPATCH DATE');
          dispatch(dbActions.setLastSyncTimestamp(newestTimestamp))
        }
      })

      fb_handle.collection('scans_' + eventId).where('lastchange', '>', date).onSnapshot(async (snapshot) => {
        console.debug('Scans snapshot');
        const allChanges = snapshot.docChanges()
        console.debug(`Got ${allChanges.length} scans`);

        await Promise.all(
          allChanges.map(change => {
            // console.debug('change--->', change.type, change.doc.id)
            if (change.type === 'removed') {
              return idxdb.scans.delete(change.doc.id)/*.then(() => {
              // console.debug('Del OK...', change.doc.id)
            })*/
            } else {
              const data = change.doc.data()
              if (data.lastchange.seconds > newestTimestamp) {
                // console.debug('IS NEWER2');
                newestTimestamp = data.lastchange.seconds
              }
              return idxdb.scans.put({ data, key: change.doc.id })/*.then(() => {
              // console.debug('Add OK...', change.doc.id)
            })*/
            }
          })
        )
        console.info('Finished adding scans');
        const items1 = await idxdb.scans.toArray()
        // console.debug('Got scans items', items1);
        // const items2 = items1.map(record => { return record.data })
        // console.debug('Got scans items2', items2);
        dispatch(dbActions.setScans(items1))

        // console.debug('FINISHED - NEWEST', newestTimestamp);
        // console.debug('PREV', lastSyncTimestamp);
        if (newestTimestamp > lastSyncTimestamp) {
          // console.debug('DISPATCH DATE');
          dispatch(dbActions.setLastSyncTimestamp(newestTimestamp))
        }
      })
    } else {
      const items1 = await idxdb.qrcodes.toArray()  // toArray -> Promise
      const items2 = items1.map(record => { return record.data })
      dispatch(dbActions.setItems(items2))
      const scans = await idxdb.scans.toArray()
      dispatch(dbActions.setScans(scans))
    }
    // aeslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, dispatch, fbLogin, fbPwd/*, lastSyncTimestamp*/])

  useEffect(() => {
    // WARNING !!!!!! Ce code provoque un gros pb de multiples re-rendering !!!
    // console.debug('isLatestVersion', isLatestVersion)
    // if (!isLatestVersion) {
    //   console.debug('emptyCacheStorage')
    //   emptyCacheStorage();
    // } else {
    //   console.debug('emptyCacheStorage : OK')
    // }

    if (isLogged && isInit) {
      dispatch(dbActions.setItems([]))
      initDB()
      if (udid === null) {
        dispatch(authActions.setUdid(uuidv4()))
      }
    }
  }, [isLogged, isInit, dispatch, udid, initDB/*, isLatestVersion, emptyCacheStorage*/])

  // NB : Pas d'utilisation du Lazyloading, mais code prêt si besoin (<Suspense>)
  // Vidéo 292 Maximilian
  return (
    <Router>
      <div className="App">
        {/* <ButtonLink onClick={clearCacheData}>Clear</ButtonLink> */}
        <p className="warn-landscape">Cette application ne fonctionne pas en mode horizontal. Veillez tourner votre appareil verticalement.</p>
        <Suspense fallback={<p className="white">Chargement...</p>}>
          <Switch>
            <Route exact path="/">
              {(isLogged && isInit) ? <Redirect to="/home" /> : <Welcome />}
            </Route>
            <Route exact path="/init">
              <Init />
            </Route>
            <Route exact path="/home">
              <Home />
            </Route>
            <Route exact path="/scanner">
              <Scanner />
            </Route>
            <Route exact path="/search">
              <Search />
            </Route>
            <Route exact path="/admin">
              <Admin />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
          </Switch>
        </Suspense>
      </div>
    </Router>
  )
}

export default App
