import './Home.scss'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Title from './Title'
import ButtonLink from './ButtonLink'
import BottomSignature from './BottomSignature'
import pictoScan from '../img/picto-scan.png'
import pictoScan2x from '../img/picto-scan@2x.png'
import pictoRecherche from '../img/picto-recherche.png'
import pictoRecherche2x from '../img/picto-recherche@2x.png'
import pictoAdmin from '../img/picto-admin.png'
import pictoAdmin2x from '../img/picto-admin@2x.png'
import { Fragment, useState } from 'react'
import { emptyDatabase, idxdb } from './Firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTools } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { dbActions } from '../redux/db'

const Home = () => {
   const dispatch = useDispatch()
   const withFakeData = useSelector(state => state.db.withFakeData)
   const deviceName = useSelector(state => state.auth.deviceName)
   const items = useSelector(state => state.db.items)
   const fields = useSelector(state => state.db.fields)
   const history = useHistory();
   const showList = useSelector(state => state.db.list)
   const [showFakeDataMenu, setShowFakeDataMenu] = useState(false)

   console.debug('WithFakeData_', withFakeData)
   // console.debug('ShowList', showList);

   const goScanner = () => {
      history.push('/scanner')
   }

   const goSearch = () => {
      history.push('/search')
   }

   // const goWelcome = () => {
   //    history.push('/')
   // }

   const goAdmin = () => {
      history.push('/admin')
   }

   const resetDB = () => {
      console.debug('ResetDB')
      if (/*(process.env.NODE_ENV === 'development') && */withFakeData) {
         emptyDatabase()
         setShowFakeDataMenu(false)
      }
   }

   const addRecords100 = () => {
      addRecords(100)
   }

   const addRecords1000 = () => {
      addRecords(1000)
   }

   const addRecords10000 = () => {
      addRecords(10000)
   }

   const addRecords30000 = () => {
      addRecords(30000)
   }

   const addRecords = async (count) => {
      console.debug(`addRecords ${count} recs`)
      if (/*(process.env.NODE_ENV === 'development') && */withFakeData) {
         console.debug('Boucle')
         for (var loop = 1; loop <= count; loop++) {
            const key = `record_${loop}`
            const data = {
               qrcode: key,
               html: null
            }
            for (const field of fields) {
               data[field.name] = `${field.name}_${loop}`
            }

            await idxdb.qrcodes.put({ data, key: key })
         }

         console.debug('Finished adding fakedata');
         const items1 = await idxdb.qrcodes.toArray()  // toArray -> Promise
         // console.debug('Got items', items1);
         const items2 = items1.map(record => { return record.data })
         // console.debug('Got items2', items2);
         dispatch(dbActions.setItems(items2))
         setShowFakeDataMenu(false)
      } else {
         console.debug('Pas d\'ajout')
      }
   }

   const showTools = () => {
      console.debug('showTools', showTools ? 'Y' : 'N')
      setShowFakeDataMenu(!showFakeDataMenu)
   }

   return (
      <div className="home">
         <Title className="top">
            {/*(process.env.NODE_ENV === 'development') && */withFakeData &&
               <div className="top-left-icon">
                  <ButtonLink onClick={showTools}><FontAwesomeIcon icon={faTools} /></ButtonLink>
                  {showFakeDataMenu &&
                     <Fragment>
                        <p>La BDD contient {items.length} enreg(s)</p>
                        <ul>
                           <li><ButtonLink onClick={resetDB}>RàZ DB</ButtonLink></li>
                           <li><ButtonLink onClick={addRecords100}>100 enregs</ButtonLink></li>
                           <li><ButtonLink onClick={addRecords1000}>1000 enregs</ButtonLink></li>
                           <li><ButtonLink onClick={addRecords10000}>10000 enregs</ButtonLink></li>
                           <li><ButtonLink onClick={addRecords30000}>30000 enregs</ButtonLink></li>
                        </ul>
                     </Fragment>
                  }
               </div>
            }
            <img src={pictoAdmin} alt="logo" className="top-right-icon" srcSet={`${pictoAdmin} 1x, ${pictoAdmin2x} 2x`} onClick={goAdmin} />
            {deviceName}
         </Title>
         <div className="middle">
            <img src={pictoScan} alt="logo" srcSet={`${pictoScan} 1x, ${pictoScan2x} 2x`} onClick={goScanner} />
            <ButtonLink onClick={goScanner}>Scanner</ButtonLink>
            {showList &&
               <Fragment>
                  <img src={pictoRecherche} alt="logo" srcSet={`${pictoRecherche} 1x, ${pictoRecherche2x} 2x`} onClick={goSearch} />
                  <ButtonLink onClick={goSearch}>Rechercher</ButtonLink>
               </Fragment>
            }
         </div>
         <BottomSignature />
      </div>
   );
}

export default Home;