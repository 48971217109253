import { createSlice } from '@reduxjs/toolkit'

const _VERSION = '2.2.18'
const _MAX_SIZE_DISPLAY = 250 // Max items sur la liste Search
const _WITH_FAKE_DATA = 'db.with_fake_data'
const _EVENT_ID = 'event.id'
const _EVENT_FIELDS = 'event.fields'
const _EVENT_HTML = 'event.html'
const _EVENT_ADMIN_LOGIN = 'event.admin'
const _EVENT_ADMIN_PWD = 'event.pwd'
const _EVENT_HAS_DETAIL = 'event.has_detail'
const _EVENT_SINGLE = 'event.single'
const _EVENT_LIST = 'event.list'
const _EVENT_NAME = 'event.name'
const _FB_LAST_SYNC_TIMESTAMP = 'fb.last_sync_timestamp'
const _FB_LOGIN = 'fb.login'
const _FB_PWD = 'fb.pwd'

const event_fields = window.localStorage.getItem(_EVENT_FIELDS)
// console.debug('1->', event_fields)
const initialState = {
   version: _VERSION,
   maxSizeDisplay: _MAX_SIZE_DISPLAY,
   fields: (event_fields && (event_fields.length > 0)) ? JSON.parse(window.localStorage.getItem(_EVENT_FIELDS)) : [],
   withFakeData: (window.localStorage.getItem(_WITH_FAKE_DATA) === 'true'),
   eventId: window.localStorage.getItem(_EVENT_ID) || 0,
   html: window.localStorage.getItem(_EVENT_HTML) || '',
   adminLogin: window.localStorage.getItem(_EVENT_ADMIN_LOGIN) || null,
   adminPwd: window.localStorage.getItem(_EVENT_ADMIN_PWD) || null,
   hasDetail: (window.localStorage.getItem(_EVENT_HAS_DETAIL) === 'true'),
   single: (window.localStorage.getItem(_EVENT_SINGLE) === 'true'),
   list: (window.localStorage.getItem(_EVENT_LIST) === 'true'),
   eventName: window.localStorage.getItem(_EVENT_NAME) || '???',
   lastSyncTimestamp: window.localStorage.getItem(_FB_LAST_SYNC_TIMESTAMP) || 0,
   fbLogin: window.localStorage.getItem(_FB_LOGIN) || null,
   fbPwd: window.localStorage.getItem(_FB_PWD) || null,
   items: [],
   scans: null,
}
// console.debug('2->', initialState)

const dbSlice = createSlice({
   name: 'db',
   initialState: initialState,
   reducers: {
      setHtml(state, action) {
         console.debug('setHtml', action.payload)
         state.html = action.payload
         window.localStorage.setItem(_EVENT_HTML, action.payload)
      },
      setEventId(state, action) {
         console.debug('setEventId', action.payload)
         state.eventId = action.payload
         window.localStorage.setItem(_EVENT_ID, action.payload)
      },
      setAdminLogin(state, action) {
         console.debug('setAdminLogin', action.payload)
         state.adminLogin = action.payload
         window.localStorage.setItem(_EVENT_ADMIN_LOGIN, action.payload)
      },
      setAdminPwd(state, action) {
         console.debug('setAdminPwd', action.payload)
         state.adminPwd = action.payload
         window.localStorage.setItem(_EVENT_ADMIN_PWD, action.payload)
      },
      setFbLogin(state, action) {
         // console.debug('setFbLogin', action.payload)
         state.fbLogin = action.payload
         window.localStorage.setItem(_FB_LOGIN, action.payload)
      },
      setFbPwd(state, action) {
         // console.debug('setFbPwd', action.payload)
         state.fbPwd = action.payload
         window.localStorage.setItem(_FB_PWD, action.payload)
      },
      setHasDetail(state, action) {
         console.debug('setHasDetail', action.payload)
         state.hasDetail = (action.payload ? true : false)
         window.localStorage.setItem(_EVENT_HAS_DETAIL, (action.payload ? true : false))
      },
      setSingle(state, action) {
         console.debug('setSingle', action.payload)
         state.single = (action.payload ? true : false)
         window.localStorage.setItem(_EVENT_SINGLE, (action.payload ? true : false))
      },
      setList(state, action) {
         console.debug('SetList', action.payload)
         state.list = (action.payload ? true : false)
         window.localStorage.setItem(_EVENT_LIST, (action.payload ? true : false))
      },
      setEventName(state, action) {
         console.debug('setEventName', action.payload)
         state.eventName = action.payload
         window.localStorage.setItem(_EVENT_NAME, action.payload)
      },
      setLastSyncTimestamp(state, action) {
         console.debug('setLastSyncTimestamp', action.payload)
         state.lastSyncTimestamp = action.payload
         window.localStorage.setItem(_FB_LAST_SYNC_TIMESTAMP, action.payload)
      },
      setFields(state, action) {
         console.debug('setFields', action.payload)
         state.fields = [...action.payload]
         console.debug('setFields-2', state.fields)
         window.localStorage.setItem(_EVENT_FIELDS, JSON.stringify(action.payload))
      },
      setWithFakeData(state, action) {
         console.debug('setWithFakeData', action.payload)
         state.withFakeData = (action.payload ? true : false)
         window.localStorage.setItem(_WITH_FAKE_DATA, (action.payload ? true : false))
      },
      setItems(state, action) {
         // console.debug('setItems', action.payload)
         state.items = [...action.payload]
      },
      setScans(state, action) {
         // console.debug('setScans', action.payload)
         // state.scans = [...action.payload]
         const allScans = {}
         // console.debug('init', allScans)
         action.payload.forEach(scan => {
            const qrcode = scan['data']['qrcode']
            const key = scan['key']
            if (!(qrcode in allScans)) {
               allScans[qrcode] = []
               // console.debug('1er', qrcode, allScans)
            }
            allScans[qrcode].push({ ...scan['data'], key })
            // console.debug('autres', allScans)
         })
         state.scans = allScans
      }
   }
})

// console.debug('DbSlice', dbSlice)

export const dbActions = dbSlice.actions

export default dbSlice.reducer
