import './Admin.scss'
import { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
// import { authActions } from '../redux/auth'
import Title from './Title';
import Field from './Field';
import ButtonLink from './ButtonLink';
import { sha256 } from 'js-sha256';

import { api_setDeviceName } from '../modules/api'
import { authActions } from '../redux/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { fb_makeDateFromTimestamp, emptyDatabase } from './Firestore';

const Admin = () => {
   const [isAdmin, setIsAdmin] = useState(false)
   const [error, setError] = useState('')
   const [login, setLogin] = useState('')
   const [pwd, setPwd] = useState('')
   const [nbSelfScanned, setNbSelfScanned] = useState(0)
   // const [nbTotalScans, setNbTotalScans] = useState(0)
   const [canDisable, setCanDisable] = useState(false)
   const [newName, setNewName] = useState(null)
   // const dispatch = useDispatch()
   const adminLogin = useSelector(state => state.db.adminLogin)
   const adminPwd = useSelector(state => state.db.adminPwd)
   const deviceName = useSelector(state => state.auth.deviceName)
   const eventName = useSelector(state => state.db.eventName)
   const lastSyncTimestamp = useSelector(state => state.db.lastSyncTimestamp)
   const items = useSelector(state => state.db.items)
   const allScans = useSelector(state => state.db.scans)
   const dispatch = useDispatch()
   const history = useHistory()

   useEffect(() => {
      var vals = { count: 0, total: 0 }
      if (allScans) {
         vals = Object.values(allScans).reduce((counts, scans) => {
            // console.debug(scans);
            var add = 0;
            for (const scan of scans) {
               if (scan.device_name === deviceName) {
                  add = 1
               }
            }
            return { count: counts.count + add, total: counts.total + scans.length }
         }, vals)
      }
      setNbSelfScanned(vals.count)
      // setNbTotalScans(vals.total)
   }, [allScans, deviceName])

   // console.debug(allScans)
   // console.debug('devName', deviceName);
   const loginChanged = (data) => {
      setLogin(data)
   }

   const pwdChanged = (data) => {
      setPwd(data)
   }

   const newNameChanged = (data) => {
      setNewName(data)
   }

   const doEditNewName = () => {
      setNewName(deviceName)
   }

   const doSetNewName = () => {
      api_setDeviceName(newName).then((data) => {
         setNewName(null)
         setError(null)
      }).catch(err => {
         setError('Problème : ' + err.statusText)
         console.error('ERR SETNAME', err)
      })
   }

   const doLoginAdm = () => {
      // console.debug('pwd', sha256(pwd));
      // console.debug('adminPwd', adminPwd);
      if ((login === adminLogin) && (sha256(pwd) === adminPwd)) {
         setError('')
         setIsAdmin(true)
      } else {
         setError('Erreur')
         setIsAdmin(false)
      }
   }

   const doLogout = () => {
      if (canDisable) {
         dispatch(authActions.setIsLogged(false))
         dispatch(authActions.setIsInit(false))
         emptyDatabase().then(() => {
            console.info('Dexie.delete OK')
            history.push('/')
         }).catch(err => console.error('Dexie.delete error', error))
      }
   }

   const askLogout = () => {
      setCanDisable(true)
   }

   const cancelAskLogout = () => {
      setCanDisable(false)
   }

   const goBack = () => {
      history.goBack()
   }

   const goAbout = () => {
      history.push('/about')
   }

   const lastSync = fb_makeDateFromTimestamp(lastSyncTimestamp)
   // console.debug(lastSync)
   // console.debug('newName', newName);

   return (
      <div className="admin">
         <Title className="top">
            Admin "{eventName}"
         </Title>
         <div className="middle">
            {isAdmin && (newName === null) &&
               <Fragment>
                  <div className="zone-info">
                     <div>
                        <p>Nom de votre terminal</p>
                        <p>{deviceName}</p>
                     </div>
                     <ButtonLink onClick={doEditNewName}>Changer</ButtonLink>
                  </div>
                  <div className="zone-info">
                     <div>
                        <p>Fiches invités</p>
                        <p>{items.length}</p>
                     </div>
                  </div>
                  <div className="zone-info">
                     <div>
                        {/* <p>Invités scannés par cet appareil (sur {nbTotalScans} scans)</p> */}
                        <p>Invités scannés par le terminal ${deviceName}</p>
                        <p>{nbSelfScanned} / {(allScans ? Object.keys(allScans).length : 0)}</p>
                     </div>
                  </div>
                  <div className="zone-info">
                     <p>Dernière synchronisation : {lastSync.toLocaleDateString('fr-FR')} à {lastSync.toLocaleTimeString('fr-FR')}</p>
                  </div>
                  <div className="zone-info">
                     <ButtonLink onClick={askLogout}>Désactiver cet appareil</ButtonLink>
                     {canDisable &&
                        <Fragment>
                           <ButtonLink onClick={doLogout}>OUI <FontAwesomeIcon icon={faExclamationTriangle} /></ButtonLink>
                           <ButtonLink onClick={cancelAskLogout}>NON</ButtonLink>
                        </Fragment>
                     }
                  </div>
               </Fragment>
            }
            {
               isAdmin && (newName !== null) && <Fragment>
                  <Field onChange={newNameChanged} value={newName} />
                  <ButtonLink onClick={doSetNewName}>Valider</ButtonLink>
               </Fragment>
            }
            {(error !== '') && <p className="error">{error}</p>}
            {!isAdmin &&
               <Fragment>
                  <Field onChange={loginChanged} value={login} placeholder="Identifiant"></Field>
                  <Field onChange={pwdChanged} value={pwd} isPassword placeholder="Mot de passe"></Field>
                  <ButtonLink onClick={doLoginAdm}>Se connecter</ButtonLink>
               </Fragment>
            }
         </div>
         <div className="bottom">
            <ButtonLink onClick={goBack}>Retour</ButtonLink>
            <ButtonLink onClick={goAbout}>A propos</ButtonLink>
         </div>
      </div>
   );
}

export default Admin;