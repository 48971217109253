import './Field.scss'
import { useRef } from 'react';

const Field = (props) => {
   const fieldRef = useRef()
   const onChange = () => {
      props.onChange(fieldRef.current.value)
   }
   return (
      <input type={props.isPassword ? 'password' : 'search'} autoCapitalize="off" onChange={onChange} ref={fieldRef} value={props.value} placeholder={props.placeholder} />
   );
}

export default Field;