import { useSelector } from 'react-redux'
import './BottomSignature.scss'

function BottomSignature() {
   const version = useSelector(state => state.db.version)

   return (
         <div className="bottom-sig">
            <span>&copy; Convives - Ver {version}</span>
         </div>
   )
}

export default BottomSignature