import './Title.scss'

const Title = (props) => {
   const classNames = `${props.className || ''} title`
   return (
      <div className={classNames}>
         <div>{props.children}</div>
      </div>
   );
}

export default Title;