import store from "../redux/index"

/*
Retourne le dernier (par datetime) scan effectué pour un QRCode
Retourne null si pas trouvé (QRCode inconnu ou pas scanné)
*/
export const getLastScan = (qrcode) => {
   const scans = getScans(qrcode)
   // console.debug('getLastScan', scans);
   if (scans === null) {
      return null
   } else {
      let arr = [...scans]
      // console.debug('ARR', arr);
      return arr.sort((a, b) => {
         // console.debug('CHK', a.datetime_scan.seconds, b.datetime_scan.seconds, b.datetime_scan.seconds - a.datetime_scan.seconds);
         return b.datetime_scan.seconds - a.datetime_scan.seconds
      })[0]
   }
}

/*
Retourne les scans pour un QRCode
Retourne null si QRCode inconnu ou pas scanné
*/
export const getScans = (qrcode) => {
   const scans = store.getState().db.scans

   // console.debug('getScans(' + qrcode + ')', scans);
   return (scans && scans[qrcode]) || null
}

/*
Retourne la présence (true/false) du dernier scan d'un invité (identifié par QRCode)
Retourne false si pas trouvé ou si invité "dé-scanné"
*/
export const getPresenceFor = (qrcode) => {
   const lastscan = getLastScan(qrcode)
   return ((lastscan !== null) ? lastscan.on : false)
}

/*
Retourne un item par son QRCode
*/
export const getItemByCode = (qrcode) => {
   const items = store.getState().db.items

   for (const item of items) {
      // console.debug('codeExists ?', item.qrcode, qrcode);
      if (item.qrcode === qrcode) {
         return item
      }
   }
   return null
}

/*
Retourne (true/false) si un QRCode existe
*/
export const codeExists = (qrcode) => {
   return (getItemByCode(qrcode) !== null)
}

/*
Retourne (true/false) si un QRCode a déjà été scanné
*/
export const isScannedYet = (qrcode) => {
   const scans = store.getState().db.scans

   return scans && (qrcode in scans)
}

// Tweak pour bug freeze de camera sur certains Android
// https://github.com/JodusNodus/react-qr-reader/issues/185
export const cleanupCamera = () => { 
   navigator.mediaDevices.getUserMedia({
      video: true,
   }).then(mediaStream => {
      mediaStream.getVideoTracks().forEach(track => {
         mediaStream.removeTrack(track)
         track.stop()
      });
   })
}
