import { configureStore } from '@reduxjs/toolkit'
import authReducer from './auth'
import dbReducer from './db'

const store = configureStore({
   reducer: {
      auth: authReducer,
      db: dbReducer,
   }
})

export default store