import './ListItem.scss'
import { api_postScan } from '../modules/api'
import { /*isScannedYet, */getPresenceFor, getLastScan } from '../modules/utils'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle/*, faTimesCircle*/ } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'

const ListItem = (props) => {
   // console.debug('PROPS', props);
   // const [yet, setYet] = useState(false)
   const [isOn, setIsOn] = useState(false)
   // const single = useSelector(state => state.db.single)
   const allScans = useSelector(state => state.db.scans)
   const fields = useSelector(state => state.db.fields)

   // console.debug('PROPS', props.key);
   // console.debug('__HTML__', useSelector(state => state.db.html));

   useEffect(() => {
      const lastScan = getLastScan(props.item.qrcode)

      // setYet(isScannedYet(props.item.qrcode))
      getPresenceFor(props.item.qrcode)
      setIsOn((lastScan === null) ? false : lastScan.on)
   }, [allScans, props.item.qrcode])

   const doShowDetail = () => {
      props.onShowDetail(props.item)
   }

   const doPostScanOn = () => {
      // console.debug('doPostOn', props);
      // if (!single || !yet) {
      //    api_postScan(props.item.qrcode, 'manuel', true)
      // } else {
      //    console.info('Erreur : Déjà scanné !')
      // }
      api_postScan(props.item.qrcode, 'manuel', true)
   }

   const doPostScanOff = () => {
      // console.debug('doPostOff', props);
      // if (!single || !yet) {
      //    api_postScan(props.item.qrcode, 'manuel', false)
      // } else {
      //    console.info('Erreur : Déjà scanné !')
      // }
      api_postScan(props.item.qrcode, 'manuel', false)
   }

   // console.debug('FIELDS', fields)
   const fields_vals = [...fields].filter(field => {
      // console.debug('Filter ? ', field, field.display_order > 0);
      return field.display_order > 0
   }).sort((a, b) => {
      // console.debug('A', a);
      // console.debug('B', b);
      // console.debug('RES', a.display_order - b.display_order);
      return a.display_order - b.display_order
   }).reduce((arr, field) => {
      return [...arr, field.name]
   }, [])
   // console.debug('FIELDS VALS', fields_vals)

   return (
      <tr className="list-item">
         {fields_vals.map((field) => {
            return <td onClick={doShowDetail}>{props.item[field]}</td>
         })}
         <td>
            {/* {single && yet && <FontAwesomeIcon className="fa-toggle-on" icon={faCheckCircle} />}
            {!single && isOn && <FontAwesomeIcon className="fa-toggle-on" icon={faCheckCircle} onClick={doPostScanOff} />}
            {!single && yet && !isOn && <FontAwesomeIcon className="fa-toggle-off" icon={faTimesCircle} onClick={doPostScanOn} />}
            {!yet && <FontAwesomeIcon className="fa-toggle-unset" icon={faCircle} onClick={doPostScanOn} />} */}
            {isOn && <FontAwesomeIcon className="fa-toggle-on" icon={faCheckCircle} onClick={doPostScanOff} />}
            {!isOn && <FontAwesomeIcon className="fa-toggle-unset" icon={faCircle} onClick={doPostScanOn} />}
         </td>
      </tr>
   );
}

export default ListItem;