import store from "../redux/index"
import { authActions } from "../redux/auth"
import { dbActions } from "../redux/db"
import { fb_handle, fb_makeTimestampFromDate, idxdb } from '../components/Firestore'
// store.dispatch({type: ... })
// const events = store.getState().events
// store.subscribe(() => console.debug("The store has been modified"))

// import firebase from 'firebase/app'
// import 'firebase/firestore'

export const api_setDeviceName = (name) => {
   const url = store.getState().auth.apiUrl
   const udid = store.getState().auth.udid

   console.log('SET DEVICE...', name);
   return new Promise(async (success, failure) => {
      const response = await fetch(url + '/graphql', {
         method: 'POST',
         body: JSON.stringify({
            query: `query {
               set_name(NameInput: { udid: "${udid}", name: "${name}" }) {
                  udid
                  name
               }
            }`
         }),
         headers: {
            'Content-type': 'application/json'
         }
      })
      if (response.ok) {
         const data = await response.json()

         console.debug(authActions);
         store.dispatch(authActions.setDeviceName(name))
         success(data)
      } else {
         failure(response)
      }
   })
}

export const api_doLogin = (login, password) => {
   const url = store.getState().auth.apiUrl
   const udid = store.getState().auth.udid

   console.log('DO LOGIN...');
   return new Promise(async (success, failure) => {
      const response = await fetch(url + '/graphql', {
         method: 'POST',
         body: JSON.stringify({
            query: `query {
               login(LoginInput: { login: "${login}", password: "${password}", udid: "${udid}" }) {
                  event_id
                  assets {
                     name
                     data
                     type
                  }
                  fields {
                     name
                     label
                     searchable
                     sort_order
                     display_order
                     width
                  }
                  html
                  admin_login
                  admin_pwd
                  fb_login
                  fb_pwd
                  has_detail
                  single
                  list
                  event_name
               }
            }`
         }),
         headers: {
            'Content-type': 'application/json'
         }
      })
      if (response.ok) {
         const data = await response.json()

         console.log('LOGIN OK');
         success(data)
      } else {
         failure(response)
      }
   })
}

export const api_postScan = (qrcode, mode, on) => {
   const device_name = store.getState().auth.deviceName
   const eventId = store.getState().db.eventId
   const withFakeData = store.getState().db.withFakeData
   const date = fb_makeTimestampFromDate(new Date())
   const record = {
      datetime_scan: date,
      lastchange: date,
      device_name,
      mode,
      on,
      qrcode
   }
   console.log('POST SCAN...', record);

   if ((process.env.NODE_ENV === 'development') && withFakeData) {
      return new Promise(async (success, failure) => {
         try {
            await idxdb.scans.put({ data: record, key: qrcode })
            console.info('Finished adding scans');
            const items = await idxdb.scans.toArray()
            store.dispatch(dbActions.setScans(items))
            success()
         } catch (err) {
            failure(err)
         }
      })
   } else {
      return new Promise(async (success, failure) => {
         fb_handle.collection('scans_' + eventId).add(record).then(() => {
            success()
         }).catch(err => {
            failure(err)
         })
      })
   }
}
