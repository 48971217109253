import parse from 'html-react-parser'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getPresenceFor/*, isScannedYet*/ } from '../modules/utils'
import './Detail.scss'
import { idxdb } from './Firestore'
import ButtonLink from './ButtonLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle/*, faTimesCircle*/ } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'

const Detail = (props) => {
   // console.debug('DETAIL = ', props)
   // const [yet, setYet] = useState(false)
   // const [isOn, setIsOn] = useState(false)
   const [curPresence, setCurPresence] = useState(null)
   const [html, setHtml] = useState(null)
   const allScans = useSelector(state => state.db.scans)
   const globalHtml = useSelector(state => state.db.html)
   // const single = useSelector(state => state.db.single)
   const fields = useSelector(state => state.db.fields)

   // console.debug('FIELDS', fields)
   // console.debug('PRESENCE2 ? ', /*curPresence, */props.item)
   // console.debug('HTML ? ', /*curPresence, */html)
   // console.debug('__HTML__2__', useSelector(state => state.db.html));

   const setImages = async (html) => {
      const regexp = /<img2 +name="([^"]+)" *\/>/g;
      // const all_img2 = [...str.matchAll(regexp)];
      const all_tags = [...html.matchAll(regexp)]

      // console.debug('REGEXP', regexp)
      // console.debug('HTML', html)
      // console.debug('_ALL IMG', all_tags)

      var res = html
      for (const tag_parts of all_tags) {
         // console.debug('Part : ', tag_parts)
         // console.debug('Part[0] : ', tag_parts[0])
         // console.debug('Part[1] : ', tag_parts[1])
         // console.debug('REPLACE on ' + tag_parts[1]);

         try {
            const image = await idxdb.images.get(tag_parts[1])
            // console.debug('***ICI***', image);
            res = res.replaceAll(tag_parts[0], `<img src="data:${image.data.type};base64,${image.data.base64}" />`)
         } catch (err) {
            // console.debug('***LA***', err);
            res = res.replaceAll(tag_parts[0], `<span>IMG ${tag_parts[1]} ???</span>`)
         }
         // console.debug('RES', res);
      }

      // console.debug('RES FINAL', res);
      return res
   }

   useEffect(() => {
      const setFields = async (html) => {
         var res = html

         for (const field of fields) {
            res = res.replaceAll(`%${field.name}%`, props.item[field.name])
         }
         return res
      }

      // setYet(isScannedYet(props.item.qrcode))
      setCurPresence(getPresenceFor(props.item.qrcode))
      if (props.item.html?.length > 0) {
         setImages(props.item.html)
            .then(html => {
               return setFields(html)
               // return html
            })
            .then(html => {
               return setHtml(html)
            })
            .catch(err => {
               setHtml(null)
            })
      } else {
         setImages(globalHtml)
            .then(html => {
               return setFields(html)
               // return html
            })
            .then(html => {
               return setHtml(html)
            })
            .catch(err => {
               setHtml(null)
            })
      }
   }, [props.item, allScans, globalHtml, fields])

   // console.debug('No Toggle', props.noToggle)
   const doSwitchOn = () => {
      if (!props.noToggle) {
         props.onPresence(true)
      }
   }

   const doSwitchOff = () => {
      if (!props.noToggle) {
         props.onPresence(false)
      }
   }

   return (
      <div className="detail">
         <div>
            {/* Invité scanné ?
            {single && yet && <FontAwesomeIcon className="fa-toggle-on" icon={faCheckCircle} />}
            {!single && curPresence && <FontAwesomeIcon className="fa-toggle-on" icon={faCheckCircle} onClick={doSwitchOff} />}
            {!single && yet && !curPresence && <FontAwesomeIcon className="fa-toggle-off" icon={faTimesCircle} onClick={doSwitchOn} />}
            {!yet && <FontAwesomeIcon className="fa-toggle-unset" icon={faCircle} onClick={doSwitchOn} />} */}
            Invité scanné ?
            {curPresence && <FontAwesomeIcon className="fa-toggle-on" icon={faCheckCircle} onClick={doSwitchOff} />}
            {!curPresence && <FontAwesomeIcon className="fa-toggle-unset" icon={faCircle} onClick={doSwitchOn} />}
            <ButtonLink onClick={props.onCloseDetail}>Fermer</ButtonLink>
         </div>
         <div className="detail-content">{html && parse(html)}</div>
      </div>
   )
}

export default Detail;
