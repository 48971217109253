import './Search.scss'
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
// import Title from './Title';
import ButtonLink from './ButtonLink';
import ListItem from './ListItem';
import Field from './Field';
// import SearchFilter from './SearchFilter';
import Detail from './Detail';
import { api_postScan } from '../modules/api'
import { Fragment } from 'react';
import pictoMiniScan from '../img/picto-scan-petit.png'
import pictoMiniScan2x from '../img/picto-scan-petit@2x.png'

const Search = () => {
   const [strFilter, setStrFilter] = useState('')
   const [showDetail, setShowDetail] = useState(false)
   const [fieldsDisplay, setFieldsDisplay] = useState(null)
   const [fieldsSearch, setFieldsSearch] = useState(null)
   const [fieldsSort, setFieldsSort] = useState(null)
   const [selectedItem, setSelectedItem] = useState(null)
   const [filteredItems, setFilteredItems] = useState(null)
   const [tooMany, setTooMany] = useState(false)
   const items = useSelector(state => state.db.items)
   const fields = useSelector(state => state.db.fields)
   const maxSizeDisplay = useSelector(state => state.db.maxSizeDisplay)
   // const html = useSelector(state => state.db.html)
   const hasDetail = useSelector(state => state.db.hasDetail)
   const history = useHistory()
   // console.debug('*_*_*_ RENDER Search', `HTML=${html}`);
   // console.debug('Items -->', items);
   // console.debug('Scans -->', scans);
   // console.debug('In Search', selectedItem)
   // console.debug('In Search', items)

   const doSort = useCallback((arr) => {
      // console.debug('DO SORT');
      if (!fieldsSort) {
         console.debug('NO SORT');
         return arr
      }
      // console.debug('ARR', arr);
      const res = [...arr].sort((a, b) => {
         // console.debug('COMP', a, b);
         for (const field of fieldsSort) {
            // console.debug('FIELD', field)
            if (!(field in a) || !(field in b)) {
               console.error(`Manque champ field dans a ou b`);
               // return 0
            } else {
               const res_comp = String(a[field]).localeCompare(String(b[field]))

               if (res_comp !== 0) {
                  return res_comp
               }
            }
            // Sinon on passe au champ suivant
         }
         return 0 // Finalement a et b sont identiques
      })
      // console.debug('SORTED', res);
      return res
   }, [fieldsSort])

   useEffect(() => {
      // Prépa liste des champs affichés
      // console.debug(fields)
      if (!fieldsDisplay && Array.isArray(fields)) {
         var filtered_fields = [...fields].filter(field => {
            // console.debug('Filter ? ', field, field.display_order > 0);
            return field.display_order > 0
         }).sort((a, b) => {
            return a.display_order - b.display_order
         }).reduce((arr, field) => {
            return [...arr, { label: field.label, width: field.width }]
         }, [])
         setFieldsDisplay(filtered_fields)
         console.debug('FIELDS DISPLAY', [...filtered_fields])
      }

      // Prépa liste des champs de recherche
      if (!fieldsSearch && Array.isArray(fields)) {
         filtered_fields = [...fields].filter(field => {
            return field.searchable
         }).reduce((arr, field) => {
            return [...arr, field.name]
         }, [])
         setFieldsSearch(filtered_fields)
         console.debug('FIELDS SEARCH', [...filtered_fields])
      }

      // Prépa liste des champs de tri
      if (!fieldsSort && Array.isArray(fields)) {
         filtered_fields = [...fields].filter(field => {
            return field.sort_order > 0
         }).sort((a, b) => {
            return a.sort_order - b.sort_order
         }).reduce((arr, field) => {
            return [...arr, field.name]
         }, [])
         setFieldsSort(filtered_fields)
         console.debug('FIELDS SORT', [...filtered_fields])
      }

      if (!fieldsSearch || (strFilter.length === 0)) {
         setFilteredItems(doSort(items))
         setTooMany(items.length > maxSizeDisplay)
      } else {
         const str_search = strFilter.toUpperCase()
         const res = items.filter((item) => {
            for (const field_name of fieldsSearch) {
               if (String(item[field_name]).toUpperCase().indexOf(str_search) >= 0) {
                  return true
               }
            }
            return false
         })
         setFilteredItems(doSort(res))
         setTooMany(res.length > maxSizeDisplay)
      }
   }, [fields, items, strFilter, fieldsDisplay, fieldsSearch, fieldsSort, doSort, maxSizeDisplay])

   const doFilter = (data) => {
      setStrFilter(data.trim())
   }

   const doShowDetail = (item) => {
      if (hasDetail) {
         // console.debug('Show detail...', item)
         // console.debug('**SetSelectedItem 1')
         setSelectedItem(item)
         setShowDetail(true)
         console.debug(window.scrollY)
      // } else {
      //    console.debug('DOES NOT HAVE DETAIL!!!');
      }
   }

   const doCloseDetail = () => {
      // console.debug('Close detail');
      // console.debug('**SetSelectedItem 2')
      setSelectedItem(null)
      setShowDetail(false)
   }

   const goScanner = () => {
      history.push('/scanner')
   }

   const goHome = () => {
      history.push('/home')
   }
   const doOnPresence = (on) => {
      // console.debug('Switch presence: ', on, selectedItem);
      api_postScan(selectedItem.qrcode, 'manuel', on)
   }

   // console.debug('LISTE****')
   // const ret = items.filter((item) => {
   //    return strFilter === '' || (item.nom?.toUpperCase().indexOf(strFilter) >= 0) || (item.prenom?.toUpperCase().indexOf(strFilter) >= 0)
   // }).map(item => {
   //    console.debug('Item QRCode', item.qrcode);
   //    return ''
   // })
   // console.debug('****LISTE', ret)

   // console.debug('Search items', filteredItems)

   return (
      <div className="search">
         {showDetail &&
            <Detail onCloseDetail={doCloseDetail} item={selectedItem} onPresence={doOnPresence} />
         }
         {!showDetail &&
            <Fragment>
               <div className="top">
                  <ButtonLink className="top-left-icon" onClick={goHome}>Fermer</ButtonLink>
                  <p>Recherche</p>
                  <img src={pictoMiniScan} alt="logo" className="top-right-icon" srcSet={`${pictoMiniScan} 1x, ${pictoMiniScan2x} 2x`} onClick={goScanner} />
                  <Field onChange={doFilter} value={strFilter} />
               </div>
               <div className="middle">
                  <Fragment>
                     <table>
                        <thead>
                           <tr>
                              {fieldsDisplay && fieldsDisplay.map((field) => {
                                 return <th width={field.width} key={field.name}>{field.label}</th>
                              })}
                              <th width="1%"></th>
                           </tr>
                        </thead>
                        <tbody>
                           {filteredItems && filteredItems.slice(0, maxSizeDisplay + 1).map(item => {
                              // console.debug('Item QRCode', item.qrcode);
                              return (
                                 <ListItem key={item.qrcode} item={item} onShowDetail={doShowDetail} />
                              )
                           })}
                        </tbody>
                     </table>
                     {tooMany && <p>Il y a trop de lignes, veuillez utiliser le champ de recherche pour filtrer l'affichage.</p>}
                  </Fragment>
               </div>
               {/* {!showDetail && <div className="bottom">
            <ButtonLink onClick={goHome}>Fermer</ButtonLink>
         </div>} */}
            </Fragment>
         }
      </div>
   );
}

// Search.whyDidYouRender = true

export default Search;