import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

// import store from "../redux/index"
// import { authSlice } from "../redux/auth"
import Dexie from 'dexie';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// **********************************************************************
// MODIFIER AUSSI DANS :
// - front/app/src/components/Firestore.js
// - back/sync/app/app.js
// - back/sync/app/config.js (email/pwd)
// Pour test compte Gildas décommenter ceci :

// const firebaseConfig = {
//    apiKey: "AIzaSyALdhr09bLtBWn4Iexw0GccbU6LauGEi_Q",
//    authDomain: "convives-79d31.firebaseapp.com",
//    projectId: "convives-79d31",
//    storageBucket: "convives-79d31.appspot.com",
//    messagingSenderId: "480175654604",
//    appId: "1:480175654604:web:7bee34ae60fc3b86c42469",
//    measurementId: "G-2PV2ZEXLZ8"
// }

// Pour compte Convives décommenter ceci :
const firebaseConfig = {
   apiKey: "AIzaSyDcS-T7kAnyBWhjdk60RrUN-fLTDpbscf8",
   authDomain: "webapp-de-pointage.firebaseapp.com",
   projectId: "webapp-de-pointage",
   storageBucket: "webapp-de-pointage.appspot.com",
   messagingSenderId: "44059826887",
   appId: "1:44059826887:web:bb46317b13be2954b87ce2"
}

// **********************************************************************

firebase.initializeApp(firebaseConfig)
// firebase.analytics();
export const fb_handle = firebase.firestore();

fb_handle.enablePersistence()
   .catch(err => {
      if (err.code === 'failed-precondition') {
         // Plusieurs onglets ouverts en même temps ?
         console.error('Firebase persistence failed!')
      } else if (err.code === 'unimplemented') {
         console.error('Firebase persistence not available!')
      } else {
         console.error(err)
      }
   })
   .then(() => {
      console.info('Firebase persistence enabled')
   })

export const fb_makeTimestampFromDate = (date) => {
   return firestore.Timestamp.fromDate(date)
}

export const fb_makeDateFromTimestamp = (timestamp) => {
   if (!timestamp) {
      return new Date(0)
   } else {
      // console.debug('fb_makeDateFromTimestamp_1', timestamp);
      // console.debug('fb_makeDateFromTimestamp_2', fb_makeTimestamp({ seconds: timestamp, nanoseconds: 0 }));
      // console.debug('fb_makeDateFromTimestamp_3', fb_makeTimestamp({ seconds: timestamp, nanoseconds: 0 }).toDate());
      return fb_makeTimestamp({ seconds: timestamp, nanoseconds: 0 }).toDate()
   }
}

export const fb_makeTimestamp = (timestamp) => {
   return new firestore.Timestamp(timestamp.seconds, timestamp.nanoseconds)
}

export const doLoginFirebase = (login, password) => {
   return new Promise(async (success, failure) => {
      try {
         // console.debug('TRY LOG IN', login, password, firebase)
         const userCredential = await firebase.auth().signInWithEmailAndPassword(login, password)
         // console.debug('LOGGED IN');
         success(userCredential)
      } catch (error) {
         console.error(`NOT LOGGED: ${error.code}-${error.message}`);
         failure(error)
      }
   })
}

export const emptyDatabase = async () => {
   await idxdb.qrcodes.clear()
   await idxdb.scans.clear()
   await idxdb.images.clear()
}

export const firestore = firebase.firestore

export const idxdb = new Dexie('convives')

idxdb.version(2).stores({
   qrcodes: '&key',
   scans: '&key',
   images: '&key'
})
