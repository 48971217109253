import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
// import { authActions } from '../redux/auth'
import './Welcome.scss'
import ButtonLink from './ButtonLink'
import BottomSignature from './BottomSignature'
import logo from '../img/logo-new.png';
import logo2x from '../img/logo-new@2x.png';

function Welcome() {
   // const history = useHistory()
   // const dispatch = useDispatch()
   const isLogged = useSelector(state => state.auth.isLogged)
   const isInit = useSelector(state => state.auth.isInit)
   const history = useHistory()

   console.debug('isLogged', isLogged);
   // console.debug('-2-->', window.localStorage.getItem('user.logged'));
   console.debug('isInit', isInit);

   const goInit = () => {
      history.push('/init')
   }

   const goHome = () => {
      history.push('/home')
   }

   return (
      <div className="welcome">
         <div className="top">
            <img src={logo} alt="logo" srcSet={`${logo} 1x, ${logo2x} 2x`} />
         </div>
         <div className="middle">
            <p>Chaque jour nous simplifions la gestion de l'information et l'inscription de vos invités. Nous créons sur mesure votre plateforme d'inscription pour fluidifier l'organisation de votre événement.</p>
            <a href="https://www.convives.fr">www.convives.fr</a>
            {/* {isLogged && isInit && <p onClick={doLogout}>Déconnecter</p>} */}
            {isLogged && isInit &&
               <ButtonLink onClick={goHome}>Liste</ButtonLink>
            }
            {(!isLogged || !isInit) &&
               <ButtonLink onClick={goInit}>Initialiser l'application</ButtonLink>
            }
         </div>
         <BottomSignature />
      </div>
   )
}

export default Welcome